import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { menuData } from "../data/MenuData";
import { Button } from "./Button";
import Logo from "../assets/images/logo1.png";
import { animateScroll as scroll } from "react-scroll";

const toggleHome = () => {
  scroll.scrollToTop();
};
const Header = ({toggle}) => {
  return (
    <nav className="Navbar">
      <Nav>
        <NavLink onClick={toggleHome} to="/">
          <SiteLogo src={Logo} type="img" alt="Donegan Pest Solutions logo" />
        </NavLink>
        <Bars />
        <MobileIcon onClick={toggle}>
          <Bars />
        </MobileIcon>
        <NavMenu>
          {menuData.map((item, index) => (
            <NavLink
              to={item.link}
              key={index}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              {item.title}
            </NavLink>
          ))}
          <NavTxt>Call or text: (985) 264-8247</NavTxt>
        </NavMenu>
        {/* <NavBtn>
        <Button primary="true" round="true" to="/contact">Book a Consultation</Button>
      </NavBtn> */}
      </Nav>
    </nav>
  );
};

export default Header;

const Nav = styled.nav`
  background: white;
  height: 65px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1300px) / 2);
  z-index: 100;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
`;
const NavLink = styled(Link)`
  color: #08203A;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding 0 1rem;
  height: 100%;
  cursor: pointer;
  font-family: "Bebas Neue";
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: 400;

  &:hover {
    color: #B7935E;
    transition: 0.3s ease-out;
}

`;

const Bars = styled(FaBars)`
  display: none;
  color: #08203a;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const SiteLogo = styled.img`
  display: flex;
  max-width: 250px;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;
const NavTxt = styled.div`
  font-family: "Bebas Neue";
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: 400;
  color: #b7935e;
  margin-left: 2rem;
`;

const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: ponter;
  }
`;
