import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import general from "../assets/images/general-pest.png";
import termite from "../assets/images/termite.png";
import wdir from "../assets/images/wdir.png";

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allServicesJson {
        edges {
          node {
            alt
            name
            img {
              publicURL
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `);

  function getServices(data) {
    const servicesArray = [];
    data.allServicesJson.edges.forEach((item, index) => {
      servicesArray.push(
        <ServiceCard key={index}>
          <ServiceImg
            src={item.node.img.childImageSharp.fixed.src}
            alt={item.node.alt}
            fluid={item.node.img.childImageSharp.fixed}
          />
          <ServiceInfo>
            <TextWrap>
              <ServiceTitle>
                <h2>{item.node.name}</h2>
              </ServiceTitle>
            </TextWrap>
          </ServiceInfo>
        </ServiceCard>
      );
    });
    return servicesArray;
  }

  return (
    <ServicesContainer id="services">
      <ServicesHeading>What we offer:</ServicesHeading>
      {/* <ServiceWrapper><img src={general} alt="" /></ServiceWrapper>
      <ServiceWrapper><img src={wdir} alt="" /></ServiceWrapper>
      <ServiceWrapper><img src={termite} alt="" /></ServiceWrapper> */}
      <ServiceWrapper>
        <div className="service-box">
          <img src={general} alt="general pest control" />
          <span>General Pest Control</span>
        </div>
        <div className="service-box">
          <img src={wdir} alt="wood destroying insect reports" />
          <span>Wood Destroying Insect Reports (WDIR)</span>
        </div>
        <div className="service-box">
          <img src={termite} alt="termite control" />
          <span>Termite Control</span>
        </div>
      </ServiceWrapper>
      <ServiceWrapper></ServiceWrapper>

      {/* <ServiceWrapper>{getServices(data)}</ServiceWrapper> */}
      {/* <ServicesDescr>
        <p>
          Proudly serving <span className="khaki">residential </span>and{" "}
          <span className="khaki">commercial</span> structures on the
          Northshore.
        </p>
      </ServicesDescr> */}
    </ServicesContainer>
  );
};

export default Services;

const ServicesContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  margin-bottom: 5rem;
`;

const ServicesHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #08203a;
  font-family: "Bebas Neue";
  text-shadow: 1px 1px 2px #b7935e;
  letter-spacing: 4px;
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;

const ServiceWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-items: center;
  align-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
  }

  img {
    max-width: 60%;
  }
  .service-box {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 600;
  }
`;

const ServiceCard = styled.div`
  line-height: 1.5;
  width: 100%;
  height: 400px;
  flex-direction: column;
  position: relative;
  border-radius: 10px;
  background: white;
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 1rem;
  align-items: center;
  align-content: center;
  text-align: center;
`;
const ServiceImg = styled(Img)`
  height: 40%;
  max-width: 200px;
  position: absolute;
  border-radius: 10px;
  margin-left: 80px;
`;
const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 280px) {
    padding: 0 1rem;
  }
`;

const ServicesDescr = styled.p`
  text-align: center;
  font-size: 20px;
  color: #08203a;
  margin-bottom: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size: 1.5rem;
  font-weight: 400;

  .khaki {
  }
`;

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 300px;
`;

const ServiceTitle = styled.div`
  font-weight: 400;
  font-size: 0.85rem;
  margin-left: 0.5rem;
  margin-top: 1rem;
  color: #b7935e;
`;

// KHAKI --- #B7935E

// NAVY -- #08203A
