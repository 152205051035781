import { Link } from "gatsby";
import styled from "styled-components";

export const Button = styled(Link)`
    background: ${({primary}) => (primary ? '#08203A' : '#08203A')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '16px 40px' : '10px 32px')};
    color: #fff;
    font-size: ${({ big }) => (big ? '20px' : '16px')};
    outline: none;
    border: none;
    min-width: 100px;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s !important;
    border-radius: ${({ round }) => (round ? '10px' : 'none')};
    
    &:hover {
        background: ${({primary}) => (primary ? '#B7935E' : '#B7935E')};
        color:  #08203A;
        transform: translateY(-2px);
    }
    `