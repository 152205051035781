import React, { useState } from "react";
import Header from "./Header";
import { GlobalStyle } from "./styles/GlobalStyles";
import Footer from "./Footer";
import Sidebar from "./Sidebar/index";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <GlobalStyle />
      <Header toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
