import React from "react";
import styled from "styled-components";
import Ryan from "../assets/images/pic.png";
import Joey from "../assets/images/joey-headshot.jpg";

const About = () => {
  return (
    <div id="about">
      <AboutHeading>Our team</AboutHeading>
      <AboutContainer>
        <AboutWrapper>
          <PhotoContainer>
            <img src={Ryan} alt="Ryan Donegan, owner and operator of Donegan Pest Solutions" className="right" />
          </PhotoContainer>
        </AboutWrapper>

        <AboutWrapper>
          <RightContainer>
            <AboutP>
              <p>
                Donegan Pest Solutions proudly serves commercial and residential
                structures across the Northshore. With over 20 years of
                experience and service, we're sure to provide you with
                solutions.
              </p>
            </AboutP>
          </RightContainer>
        </AboutWrapper>
      </AboutContainer>
    </div>
  );
};

export default About;

const AboutC = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  display: flex;
  @media screen and (max-width: 868px) {
    flex-direction: column;
    .right {
      display: none;
    }
  }
`;

const AboutContainer = styled.div`
  min-height: 100vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  display: flex;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: 868px) {
    flex-direction: column;

    .right {
    }
  }
`;

const AboutHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  color: #08203a;
  font-family: "Bebas Neue";
  text-shadow: 1px 1px 2px #b7935e;
  letter-spacing: 4px;
  @media screen and (max-width: 768px) {
    font-size: 3rem;
  }
`;
const AboutWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const PhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  img {
    max-width: 300px;
  }
`;
const AboutP = styled.p`
  max-width: 100%;
  font-size: 20px;
  line-height: 2rem;
  font-family: "Roboto";
  padding: 0 3rem;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
    padding: 0 1rem;
    margin-right: 4rem;
  }
  @media screen and (max-width: 868px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 3rem;
    margin-right: 3rem;
    p {
      font-size: 20px;
      text-align: center;
      margin-top: 4rem;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
`;
