import React from "react";
import styled from "styled-components";
import Lakefront from "../assets/images/mandeville-lakefront.jpeg";
import { Button } from "./Button";
import {Alert, AlertTitle} from "@material-ui/lab";
const Banner = () => {
  return (
      <BannerContainer>
        <BannerBg>
          <ImgBg src={Lakefront} alt="Oak trees on Mandeville Lakefront located in Mandeville, Louisiana" type="img" />
        </BannerBg>
        <BannerContent>
          <BannerItems>
            {/* <BannerH1>Donegan Pest Solutions</BannerH1> */}
            <BannerP>
              Licensed, insured, and family-owned. <br />
              Professional termite treatments and general pest control
            </BannerP>
            <Button primary={true} big={true} round={true} to="#contact">
              Schedule a Free Consultation
            </Button>
          </BannerItems>
        </BannerContent>
      </BannerContainer>
  );
};

export default Banner;

const BannerContainer = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  color: #fff;
  align-items: center;
  height: 100vh;
  padding: 0 1rem;
  position: relative;
  margin-top: 40px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 5%,
        rgba(255, 255, 255, 0.3) 17%,
        rgba(255, 255, 255, 0.2) 100%
      ),
      linear-gradient(180deg, rgba(255, 255, 255, 1) 5%, transparent 100%);
  }
`;

const BannerBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;


const BannerContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`;

const BannerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  max-height: 100%;
  padding: 0;
  color: #08203a;
  line-height: 2;
  font-weight: bold;
`;

const BannerH1 = styled.h1`
  font-size: clamp(1.5rem, 6vw, 4rem);
  margin-bottom: 1.5rem;
  letter-spacing: 3px;
  font-weight: bold;
  padding: 0 1rem;
`;

const BannerP = styled.p`
  font-size: clamp(1rem, 3vw, 3rem);
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-weight: 400;
  font-family: "Bebas Neue";
  letter-spacing: 1.5px;
  text-shadow: 1px 1px 2px #b7935e;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;
