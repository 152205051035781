import "@fontsource/bebas-neue";
import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import favicon from "../images/favicon.ico";
import Banner from "../components/Banner";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";


const IndexPage = () => {
  return (
    <Layout>
      <div className="root">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="keywords" content="pest control, northshore pest control, termite treatments, pest control Abita, Abita Springs, Covington, Mandeville, WDIR, Wood Destroying Insect Reports, general pest control, termite control, family-owned business, local pest control"/>
          <meta name="description" content="Donegan Pest Solutions, a local family-owned business located in Abita Springs, Louisiana. Servicing St. Tammany Parish (Mandeville, Abita Springs, Covington, Slidell) and surrounding areas. Termite treatments, general pest control, consultation, Wood Destroying Insect Reports (WDIR)"/>
          <title>Donegan Pest Solutions</title>
          <link rel="icon" href={favicon} />
        </Helmet>
        <Banner />
        <Services />
        <About />
        <Contact />
      </div>
    </Layout>
  );
};

export default IndexPage;

