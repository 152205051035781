import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Logo2 from "../assets/images/dps-icon.png";
import { Button } from "./Button";

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterLinksWrapper>
          <FooterDescr>
            <FooterLogo>
              <img src={Logo2} alt="Donegan Pest Solutions logo" />
              <p>
                Personalized <b>solutions</b>.
              </p>
              <br />
              <p>Call or text: (985) 264-8247</p>
              <br />
              <p>
                Email:{" "}
                <a href="mailto:donegansolutions@gmail.com">
                  donegansolutions@gmail.com
                </a>{" "}
              </p>
            </FooterLogo>
            <FooterBtn>
              <Button primary="true" round="true" to="#contact">
                Book a Consultation
              </Button>
            </FooterBtn>
          </FooterDescr>
        </FooterLinksWrapper>
        <FooterLinksWrapper>
          <FooterLinkItems>
            <FooterLinkTitle>Contact Us</FooterLinkTitle>
            <FooterLink to="#about">About</FooterLink>
            <FooterLink to="#services">Services</FooterLink>
            <FooterLink to="#contact">Get a Quote</FooterLink>
          </FooterLinkItems>
          <FooterLinkItems>
            <FooterLinkTitle>Leave us a review!</FooterLinkTitle>
            <FooterLink
              to="https://www.facebook.com/DoneganPestSolutions"
              target="_blank"
            >
              Facebook
            </FooterLink>
            <FooterLink
              to="https://www.yelp.com/biz/donegan-pest-solutions-abita-springs"
              target="_blank"
            >
              Yelp
            </FooterLink>
            <FooterLink to="#">Google Reviews</FooterLink>
          </FooterLinkItems>
        </FooterLinksWrapper>
      </FooterContainer>
      <Copyright>
        <footer
          style={{
            textAlign: "center",
            color: "gray",
            textDecoration: "none",
          }}
        >
          Copyright © {new Date().getFullYear()}, Donegan Pest Solutions
        </footer>
      </Copyright>
    </>
  );
};

export default Footer;

const FooterContainer = styled.div`
  padding: 5rem calc((100vw - 1100px) / 2);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  color: #000;
  background: #fff;

  img {
    max-width: 100px;
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    footer {
      font-size: 10px;
    }
  }
`;

const FooterDescr = styled.div`
  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  h1 {
    margin-bottom: 3rem;
    color: #f26a2e;
  }
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 0px;
    align-items: center;
    margin-bottom: 3rem;
  }
`;

const FooterLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 3rem;
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    align-items: center;
    padding: 0;
    margin-left: 0;
    align-content: center;
    justify-content: center;
  }
`;

const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 2rem;
  justify-content: center;
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
  }
`;

const FooterLinkTitle = styled.h2`
  font-size: 20px;
  margin-bottom: 16px;
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 18px;
  color: #3d3d4e;
  &:hover {
    color: #b7935e;
    transition: 0.3s ease-out;
  }
`;
const FooterBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 1rem;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding: 0;
  a {
    text-decoration: none;
    color: black;
    font-weight: 600;
  }
  @media screen and (max-width: 820px) {
    grid-template-columns: 1fr;
    text-align: center;
    justify-items: center;
    align-items: center;
    padding: 0;
    align-content: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
`;

const Copyright = styled.footer`
  a {
    text-decoration: none;
    color: gray;
  }
`;
